<template>
  <v-container id="home" fluid>
    <v-row class="pa-0 ma-0">
      <v-col cols="1" lg="1" xl="2" class="pa-0 ma-0 hidden-md-and-down">
        <v-sheet style="width: 100%; height: 100%"></v-sheet>
      </v-col>
      <v-col class="pa-0 ma-0" cols="12" lg="10" xl="8">
        <v-row>
          <v-col 
            cols="12" sm="6" md="4"
            v-for="card in cards"
            :key="card.title"
          >
            <v-card 
              :to="card.linkTo"
            >
              <v-img
                :src="require('../assets/' + card.src)"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.6)"
                height="250px"
                :alt="card.alt"
              >
                <v-card-title v-html="card.title"></v-card-title>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-6 text-center">
      <v-col cols="3" md="2" lg="3" class="pa-0 ma-0 hidden-sm-and-down">
      </v-col>
      <v-col cols="12" md="8" lg="6" >
        <h1>
          We save our customers money.
        </h1>
        <br/>
        <h2>
          As a technology first company we have developed a wide range of tools which our experts use to deliver the best savings possible for your business. 
        <br/><br/>
          <a href="/contact-us">Contact us</a> today to see what we can do for you.
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      cards: [
        { title: 'Energy Trading', src: 'energy-trading-md.png', alt:'Energy Trading Graph with Wind Turbine and power station in the background', linkTo: "/services/energy-trading" },
        { title: 'Energy Price Alerts', src: 'energy-price-alerts-md.png', alt:'Road sign reading Beware Rising Prices Next Exit', linkTo: "/services/energy-price-alerts" },
        { title: 'NIE Networks Connection Configuration', src: 'nie-connection-confi-md.png', alt:'Electric Bill with a lightbuld sitting on top of it', linkTo: "/services/nie-connection-configuration" },
        { title: 'NIE Networks Applications', src: 'nie-applications-md.png', alt:'Close up of a hand writing in a form.', linkTo: "/services/nie-applications" },
        { title: 'MIC Brokerage', src: 'mic-brokerage-md.png', alt:'Hand shake with a fade of wind turbines and solar panels in the foreground', linkTo: "/services/mic-brokerage" },
        { title: 'EV Charging', src: 'ev-charging-md.png', alt:'barchart in background, car with charging plug in foreground', linkTo: "/services/ev-charging" }
      ],
    }),
  }
</script>

<style scope>
#homeiiii{
  background-image: url('~@/assets/trading-small.jpg');
}

.transbox {
  opacity: 0.8;
}
</style>